import BBanner from "../../components/b_banner/BBanner";
import HouseAdvertisementSession from "../../components/house_advertisement_session/HouseAdvertisementSession";
import Infos from "../../components/infos/Infos";
import MainBanner from "../../components/main_banner/MainBanner";
import MostSearched from "../../components/most_searched/MostSearched";
import Suggestions from "../../components/suggestions/Suggestions";
import WrapperHome from "./Styles-Home";


const Home = () => {

    return <WrapperHome>
        <MainBanner />
        <HouseAdvertisementSession title="Anúncios Turbinados:" />
        <MostSearched />
        <Infos />
        <HouseAdvertisementSession title="Sugestões para você" /> 
        <Suggestions />
        <BBanner />
    </WrapperHome>
}

export default Home