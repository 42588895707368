import { useNavigate } from "react-router-dom";
import WrapperChatCard from "./ChatCard.styles";

const ChatCard = (props) => {
    const data = props.data
    const navigate = useNavigate()
    const handleOpen = () => {
        navigate(`/chat/${data.id}`)
    }



    function obterHoraMinuto(createdAt) {
        if (createdAt === undefined) return;
        const totalMilliseconds = (createdAt.seconds * 1000) + (createdAt.nanoseconds / 1_000_000);
        const date = new Date(totalMilliseconds);
        const horas = date.getHours();
        const minutos = date.getMinutes();
        return `${horas}:${minutos.toString().padStart(2, '0')}`;
    }

    return <WrapperChatCard onClick={handleOpen}>
        <img src={data.participantData?.photoURL} alt="" />
        <span className="body">
            <span className="username">{data.participantData?.name}</span>
            <span className="lastMessage">{data.lastMessage?.message}</span>
        </span>
        <span className="moment">{obterHoraMinuto(data.lastMessage?.sendedAt)}</span>
    </WrapperChatCard>
}

export default ChatCard