import React, { useState } from 'react';
import styled from 'styled-components';
import HouseAdvertisementCard from '../house_advertisement_card/HouseAdvertisementCard';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import theme from '../../theme';

function getWidthByVisibleItems(visibleItems) {
  let Width = "";
  let FactorToMultiple = 0;

  if (visibleItems === 4) {
    Width = "width: calc(25% - 20px);";
    FactorToMultiple = 100;
  }

  if (visibleItems === 2) {
    Width = "width: calc(50% - 20px);";
    FactorToMultiple = 200;
  }

  if (visibleItems === 1) {
    Width = "width: calc(100% - 20px);";
    FactorToMultiple = 100;
  }

  return [Width, FactorToMultiple];
}

const CarouselContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

const CarouselWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease;
  transform: translateX(-${props => ((props.currentIndex * props.factorToMultiple) / props.visibleItems)}%);
`;

const Card = styled.div`
  flex: 0 0 auto;
  ${props => props.width}
  margin: 0 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
`;

const ControllersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
  gap: 20px;

  @media (max-width: ${theme.breackpoint.mobile_2}) {
    margin-top: 15px;
    margin-bottom: 30px;
  }
`;

const Controller = styled.div`
  padding: 17px;
  border-radius: 15px;
  border: 2px solid #dce0e1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease; 
  cursor: pointer;
  user-select: none;

  svg {
    color: ${theme.fontColor.green};
    transition: color 0.3s ease;
    font-size: .8rem;
  }

  &:hover {
    background-color: ${theme.fontColor.green};
    
    svg {
      color: #dce0e1;
    }
  }
`;

const ActualSlidePanel = styled.div`
  user-select: none;
`;

const Carousel = ({ items, visibleItems }) => {
  const returnMaxScroll = () => {
    if (visibleItems === 4) {
      return items.length - 4;
    }

    if (visibleItems === 2) {
      return items.length - 4;
    }

    return items.length - 1;
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const [Width, FactorToMultiple] = getWidthByVisibleItems(visibleItems);
  const maxOfSlideshow = returnMaxScroll();

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex >= maxOfSlideshow ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex <= 0 ? maxOfSlideshow : prevIndex - 1
    );
  };

  return (
    <CarouselContainer>
      <CarouselWrapper
        currentIndex={currentIndex}
        visibleItems={visibleItems}
        factorToMultiple={FactorToMultiple}
      >
        {items.map((item, index) => (
          <Card key={index} visibleItems={visibleItems} width={Width}>
            <HouseAdvertisementCard
              img={item.img}
              uri={item.uri}
              title={item.title}
              road={item.road}
              square_meter={item.square_meter}
              bedrooms={item.bedrooms}
              showers={item.showers}
              garage={item.garage}
              base_price={item.base_price}
              price={item.price}
            />
          </Card>
        ))}
      </CarouselWrapper>
      <ControllersContainer>
        <Controller onClick={handlePrev}>
          <FaChevronLeft />
        </Controller>
        <ActualSlidePanel>
          {currentIndex + 1} de {maxOfSlideshow + 1}
        </ActualSlidePanel>
        <Controller onClick={handleNext}>
          <FaChevronRight />
        </Controller>
      </ControllersContainer>
    </CarouselContainer>
  );
};

export default Carousel;
