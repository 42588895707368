import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import theme from "../../theme";

const WrapperSuggestionCard = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    img {
        width: 100%;
        height: 150px;
        background-color: gray;
        border-top-left-radius: 35px;
        border-bottom-right-radius: 35px;
        cursor: pointer;
    }

    .infos {
        margin-top: 15px;
        display: flex;

        span {
            padding: 0px 8px;
            font-size: .875rem !important;
        }

        .date {
            color: #acacb2;
            font-family: ${theme.fontFamily.medium};
            border-right: #acacb2 1px solid;
        }

        .theme {
            color: ${theme.fontColor.orange};
            font-family: ${theme.fontFamily.medium};
        }
    }
 
    h3 {
        padding: 0px 8px;
        margin-top: 7px;
        font-size: 1.15rem !important;
        cursor: pointer;
    }

    @media (max-width: ${theme.breackpoint.mobile_2}) {
        width: 100%;

        img {
            height: 250px;
        }
    }

    @media (max-width: ${theme.breackpoint.tabletsAndSmallScreens}) and (min-width: ${theme.breackpoint.mobile_2}){
        width: 49%;
    }

    @media (max-width: ${theme.breackpoint.commonScreensAndLaptops}) and (min-width: ${theme.breackpoint.tabletsAndSmallScreens}){

        
    }
`

const SuggestionCardB = ({data}) => {
    const navigate = useNavigate()
    const goToSuggestion = () => {
        navigate(data.link)
    }

    return <WrapperSuggestionCard>  
        <img src={data.img} alt={data.title} onClick={goToSuggestion} />
        <div className="infos">
            <span className="date">{data.date}</span>
            <span className="theme">{data.theme}</span>
        </div>
        <h3 onClick={goToSuggestion} >{data.title}</h3>
    </WrapperSuggestionCard>
}

export default SuggestionCardB