import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../hooks/firebase";
import MyProperty from "../my-property/MyProperty";
import WrapperMyData from "../../views/my-data/MyData.styles";
import NavBar from "../nav-bar/NavBar";

const MyFavorites = () => {
    const [properties, setProperties] = useState(null)
    const [filteredProperties, setFilteredProperties] = useState([]);
    const [searchContext, setSearchContext] = useState("");
    const {currentUser} = useContext(AuthContext)

    const handleKeyDown = (event) => {
        const searchValue = event.target.value.toLowerCase();
        const searchTerms = searchValue.split(' ').filter(term => term.trim() !== '');
        setSearchContext(searchValue);

        const filtered = properties.filter(property => {
            return searchTerms.every(term => 
                property.street.toLowerCase().includes(term) ||
                property.number.toLowerCase().includes(term) ||
                property.neightboard.toLowerCase().includes(term) ||
                property.city.toLowerCase().includes(term) ||
                property.state.toLowerCase().includes(term)
            );
        });

        setFilteredProperties(filtered);
    };

    useEffect(() => {
        const fetchProperties = async () => {
            const propertiesCollection = collection(db, 'properties');
            const q = query(propertiesCollection, where("likedBy", "array-contains", currentUser));
        
            try {
                const propertiesSnapshot = await getDocs(q);
                const propertiesList = propertiesSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setProperties(propertiesList);
                setFilteredProperties(propertiesList);
            } catch (error) {
                console.error("Erro ao buscar propriedades:", error);
            }
        };
        

        fetchProperties().catch(error => {
            console.error("Erro ao buscar propriedades:", error);
        });
    }, [])

    return <WrapperMyData nothing={properties?.length === 0}>
        <NavBar />
        <div className="container-favorites">
            {properties?.length >= 1 && <>
                <div className="search-box">
                    <span className="title">Buscar</span>
                    <input type="text" onChange={handleKeyDown} placeholder="Ex: Condomínio Gaivota 2" />
                </div>
                <div className="properties">
                    {properties !== null && 
                        filteredProperties.map((property, index) => {
                            return <MyProperty
                                key={index+"property-element"}
                                data={{...property, currentUser: currentUser, setProperties, setFilteredProperties}}
                                hidden={true}
                            />
                        })
                    }
                </div>
            </>}
            {properties?.length === 0 &&
                <div>
                    <span className="nothing-favorites">Nenhum imóvel favoritado no momento, continue explorando o nosso site em busca do seu futuro lar dos sonhos :)</span>
                </div>
            }
        </div>
    </WrapperMyData>
}

export default MyFavorites