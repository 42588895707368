import axios from "axios";
import { initializeApp } from "firebase/app";
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, sendPasswordResetEmail, updatePassword } from "firebase/auth";
import { collection, doc, getDoc, getDocs, getFirestore, query, updateDoc, where } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
const serverURL = "https://us-central1-alugueimoveis-6808c.cloudfunctions.net"
const firebaseConfig = {
  apiKey: "AIzaSyDNAVCyOKKYlbpfozI3VK_JbDwmT82TIMk",
  authDomain: "alugueimoveis-6808c.firebaseapp.com",
  projectId: "alugueimoveis-6808c",
  storageBucket: "alugueimoveis-6808c.appspot.com",
  messagingSenderId: "365485229839",
  appId: "1:365485229839:web:02ef999bee2a2d62cce07a",
  measurementId: "G-LBQ2T49127"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
auth.languageCode = 'pt'; 
export { auth, db };

export const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email, {
      url: 'https://alugueimoveis.com/entrar',
    });
    return { success: true, message: 'E-mail de redefinição de senha enviado com sucesso!' };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export async function togglePropertyActive(propertyId) {
  const propertyRef = doc(db, 'properties', propertyId);

  try {
      const docSnap = await getDoc(propertyRef);
      if (docSnap.exists()) {
          const currentActiveStatus = docSnap.data().active;
          await updateDoc(propertyRef, {
              active: !currentActiveStatus
          });
      }
  } catch (error) {
      console.error(error);
  }
}

export async function deleteProperty(propertyId) {
  const propertyRef = doc(db, 'properties', propertyId);

  try {
      const docSnap = await getDoc(propertyRef);
      if (docSnap.exists()) {
          await updateDoc(propertyRef, {
            deletedAt: true,
            active: false
          });
      }
  } catch (error) {
      console.error(error);
  }
}

export async function getNotifications(userId) {
  axios.post(`${serverURL}/fetchNotificationsByUserId`, {
    userId
  }).then(result => {
    return result
  })
}

export async function toggleFavorite(userId, propertyId) {
  axios.post(`${serverURL}/toggleFavorite`, {
    userId, 
    propertyId
  }).then(result => {
    console.log('favoritado', result)
  })
}

const fileToObjectUrl = (file) => {
  const blob = new Blob([file], { type: file.type });
  const objectUrl = URL.createObjectURL(blob);
  return objectUrl;
};

export const uploadProfileImage = async (userId, file) => {
  try {
      const formData = new FormData();
      formData.append("image", file); // Passa o arquivo diretamente
      formData.append("data", JSON.stringify({ id: userId }));

      const result = await axios.post(
          `https://us-central1-alugueimoveis-6808c.cloudfunctions.net/updateProfileUser`,
          formData,
          {
              headers: {
                  "Content-Type": "multipart/form-data"
              }
          }
      );

      const userData = result.data.data;
      return userData.photoURL;
  } catch (error) {
      console.error("Erro ao fazer upload da imagem:", error);
      return null;
  }
};

export function gerarTokenCartao(cardData) {
  return new Promise((resolve, reject) => {
    window.PagSeguroDirectPayment.getBrand({
      cardBin: cardData.cardNumber.substring(0, 6), 
      success: (brandResponse) => {
        const brand = brandResponse.brand.name; 
        window.PagSeguroDirectPayment.createCardToken({
          cardNumber: cardData.cardNumber,
          brand: brand,
          cvv: cardData.cvv,
          expirationMonth: cardData.dueDate.split('/')[0],
          expirationYear: cardData.dueDate.split('/')[1],
          success: (tokenResponse) => {
            resolve(tokenResponse.card.token);
          },
          error: (errorResponse) => {
            reject(errorResponse);
          },
        });
      },
      error: (errorResponse) => {
        reject(errorResponse); 
      },
    });
  });
}

export function updateUserInfo(data) {
  const userDocRef = doc(db, "users", data.uid);

  return getDoc(userDocRef)
    .then((userDoc) => {
      if (userDoc.exists()) {
        const updatedData = {
          name: data.name,
          telephone: data.telephone,
        };

        return updateDoc(userDocRef, updatedData).then(() => {
          console.log("Dados do usuário atualizados com sucesso!");
          return { success: true };
        });
      } else {
        console.error("Nenhum documento encontrado!");
        return null;
      }
    })
    .catch((error) => {
      console.error("Erro ao buscar ou atualizar dados do usuário:", error);
      throw error;
    });
}

export async function getAllBlogPosts() {
  const blogCollectionRef = collection(db, "blogs"); 
  const querySnapshot = await getDocs(blogCollectionRef);

  const blogPosts = querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));

  return blogPosts;
}

export async function changePassword (currentPassword, newPassword) {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
      throw new Error("Nenhum usuário está logado.");
  }

  try {
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);

      await updatePassword(user, newPassword);

      return "Senha alterada com sucesso!";
  } catch (error) {
      if (error.code === "auth/wrong-password") {
          throw new Error ("Senha atual incorreta.");
      } else if (error.code === "auth/weak-password") {
          throw new Error ("A nova senha é muito fraca.");
      } else {
          throw new Error ("Erro ao alterar a senha. Tente novamente mais tarde.");
      }
  }
};

export async function getPropertyList() {
  const propertiesCollection = collection(db, 'properties');
  const activePropertiesQuery = query(
      propertiesCollection, 
      where('active', '==', true),
      where('status', "==", "ACTIVE")
  
  );

  const result = await getDocs(activePropertiesQuery)
  return result.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
  }))

  // getDocs(activePropertiesQuery).then(result => {
  //     const propertiesList = result.docs.map(doc => ({
  //         id: doc.id,
  //         ...doc.data()
  //     }));
      
  //     return propertiesList
  // });
  
}
