import { NavLink, useNavigate } from "react-router-dom";
import WrapperNavBar from "./NavBar.styles";
import { PiUserCircle } from "react-icons/pi";
import { IoMegaphoneOutline } from "react-icons/io5";
import { RiLockPasswordLine } from "react-icons/ri";
import { BsEyeSlash } from "react-icons/bs";
import { LiaClipboardListSolid } from "react-icons/lia";
import { RiFolderSharedLine } from "react-icons/ri";
import { getAuth, signOut } from 'firebase/auth';
import { FaRegHeart, FaCamera } from "react-icons/fa";
import { useContext, useRef } from "react";
import { AuthContext } from "../../context/AuthContext";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../hooks/firebase";
import { uploadProfileImage } from "../../hooks/firebase";

const NavBar = (props) => {
    const setShow = props.handle;
    const { currentUser, userData, setUserData } = useContext(AuthContext);
    const data = userData;
    const containsImg = data?.photoURL !== "";
    const navigate = useNavigate();
    const fileInputRef = useRef(null);

    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            uploadProfileImage(currentUser, file).then(result => {
                if (result) {
                    const userRef = doc(db, "users", currentUser);
                    updateDoc(userRef, { photoURL: result })
                        .then(() => {
                            setUserData(prevUserData => ({
                                ...prevUserData,
                                photoURL: result
                            }));
                        })
                        .catch((error) => console.error("Erro ao atualizar Firestore:", error));
                } else {
                    console.error("Falha ao atualizar a imagem de perfil.");
                }
            });
        }
    };

    const logout = () => {
        const auth = getAuth();
        try {
            signOut(auth);
            navigate("/entrar");
        } catch (error) {
            console.error("Erro ao deslogar: ", error);
        }
    };

    if (!data) return null;
 
    return (
        <WrapperNavBar>
            <div className="user-container">
                <div className="top">
                    {!containsImg ? (
                        <div className="user-img" onClick={handleImageClick}>
                            <FaCamera />
                        </div>
                    ) : (
                        <img
                            src={data.photoURL}
                            alt="Imagem de perfil"
                            className="user-img"
                            onClick={handleImageClick}
                        />
                    )}
                    <div className="user">
                        <span className="name">{data.name}</span>
                        <span className="about">{data.about}</span>
                    </div>
                </div>

                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={handleFileChange}
                />

                <NavLink className="button" to="/meus-chats"> Chat </NavLink>
            </div>
            <div className="menu">
                <NavLink className="navigate-item" to="/meus-dados">
                    <PiUserCircle /> Meus Dados
                </NavLink>
                <NavLink className="navigate-item" to="/meus-imoveis">
                    <IoMegaphoneOutline /> Anúncios
                </NavLink>
                <NavLink className="navigate-item" to="/meus-imoveis-favoritos">
                    <FaRegHeart /> Favoritos
                </NavLink>
                <NavLink className="navigate-item" to="/alterar-senha">
                    <RiLockPasswordLine /> Alterar Senha
                </NavLink>
                <NavLink className="navigate-item" to="/institucional">
                    <BsEyeSlash className="invert" /> Política de Privacidade
                </NavLink>
                <NavLink className="navigate-item" to="/meus-termos-de-uso">
                    <LiaClipboardListSolid /> Termos de Uso
                </NavLink>
                <a className="navigate-item" onClick={logout}>
                    <RiFolderSharedLine /> Finalizar sessão
                </a>
            </div>
        </WrapperNavBar>
    );
};

export default NavBar;
