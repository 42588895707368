import { useContext, useEffect, useState } from "react";
import WrapperRegisterProperty from "./ResigterProperty.styles";
import { FaXmark } from "react-icons/fa6";
import HouseIcon from "../../imgs/icons/camera_3326034.png"
import { IoIosArrowDown } from "react-icons/io";
import theme from "../../theme";
import { AuthContext } from './../../context/AuthContext';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { FiX } from "react-icons/fi";
import { FaCheck } from "react-icons/fa6";
import styled from "styled-components";


const RegisterPropertyPage = () => {
    const isMobile = window.innerWidth <= theme.breackpoint.mobile_2.replace("px", "")
    const [step, setStep] = useState(0)
    var {currentUser, userData} = useContext(AuthContext)
    console.log(userData)
    const [selectedInputs, setSelectedInputs] = useState({});
    const [bathroomQuantity, setBathroomQuantity] = useState(false)
    const [bedroomQuantity, setBedroomQuantity] = useState(false)
    const [garageQuantity, setGarageQuantity] = useState(false)
    //const [iscondominium, setIsCondominium] = useState(false)
    const [selectboxValue, setSelectBoxValue] = useState(isMobile ? "Imóvel" : "Tipo do Imóvel")
    const [showOptions, setShowOptions] = useState(false)
    const [squareMeter, setSquareMeter] = useState("")
    const [squareMeterConstruction, setSquareMeterConstruction] = useState("")
    const [price, setPrice] = useState("")
    const [condominiumPrice, setCondominiumPrice] = useState("")
    const [IPTU, setIPTU] = useState("")
    const [description, setDescription] = useState("")
    const [CEP, setCEP] = useState("")
    const [city, setCity] = useState("")
    const [street, setStreet] = useState("")
    const [number, setNumber] = useState("")
    const [complement, setComplement] = useState("")
    const [acceptsExchange, setAcceptsExchange] = useState(true)
    const [neightboard, setNeightboard] = useState("")
    const [state, setState] = useState("")
    const [checkedPrivacyPolicy, setCheckedPrivacyPolicy] = useState(false)
    const [images, setImages] = useState([])
    const navigate = useNavigate()

    
    useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // Para um efeito de scroll suave
            });
        };

        scrollToTop();
    },[])

    const handlePrice = (e) => {
        let value = e.currentTarget.value;
    
        // Remove qualquer coisa que não seja número ou ponto
        value = value.replace(/\D/g, '');
    
        // Adiciona o ponto a cada três dígitos, mas só no final
        let formattedValue = '';
        for (let i = 0; i < value.length; i++) {
          // Adiciona o número
          formattedValue += value[i];
    
          // Quando tiver 3 dígitos, e não for o último, insere o ponto
          if ((i + 1) % 3 === 0 && i !== value.length - 1) {
            formattedValue += '.';
          }
        }
    
        setPrice(formattedValue);
    };
    
    const handleAllDataFilledAndPrivacyPolice = () => {
        let property = {
            property: {
                price: formatPrice(),
                acceptsExchange,
                bathroom: parseInt(bathroomQuantity),
                bedroom: parseInt(bedroomQuantity),
                cep: CEP,
                city,
                garage: parseInt(garageQuantity),
                images: getImages().length > 0,
                neightboard,
                number,
                squareConstruction: parseInt(squareMeterConstruction),
                squareMeter: parseInt(squareMeter),
                state,
                street,
                type: selectboxValue,
                checkedPrivacyPolicy
            }
        }

        const isValid = Object.values(property.property).every(value => {
            if (Array.isArray(value)) {
                return value.length !== "";
            }
            return value !== undefined && value !== null && value !== "" && value !== false;
        });
    
        return isValid && property.property.checkedPrivacyPolicy;
    }

    const handleToggle = (id) => {
      setSelectedInputs((prevState) => ({
        ...prevState,
        [id]: !prevState[id],
      }));
    };

    function setBathroomQuantityByCheckbox(value, e) {
        let parent = e.target.parentElement;
        let buttons = parent.querySelectorAll('.checkbox');

        buttons.forEach(button => {
            button.classList.remove('active');
        });

        e.target.classList.add('active');
        setBathroomQuantity(value)
    }

    function setBedroomQuantityByCheckbox(value, e) {
        let parent = e.target.parentElement;
        let buttons = parent.querySelectorAll('.checkbox');

        buttons.forEach(button => {
            button.classList.remove('active');
        });

        e.target.classList.add('active');
        setBedroomQuantity(value)
    }

    function setGarageQuantityByCheckbox(value, e) {
        let parent = e.target.parentElement;
        let buttons = parent.querySelectorAll('.checkbox');

        buttons.forEach(button => {
            button.classList.remove('active');
        });

        e.target.classList.add('active');
        setGarageQuantity(value)
    }

    function handleSelectOption(value) {
        setSelectBoxValue(value)
        setShowOptions(false)
    }

    const handleCepBlur = (cep) => {
        setCEP(cep);
        axios.get(`https://viacep.com.br/ws/${cep}/json/`)
            .then(result => {
                const data = result.data
                if (data.erro) return;
                setCity(data.localidade);
                setStreet(data.logradouro);
                setNeightboard(data.bairro);
                setState(data.uf);
            })
            .catch(error => {
                console.log("Erro ao buscar CEP:", error);
            });
    };

    function getImages() {
        const result = images.map((item) => {
            return item.url
        })

        return result
    }

    function next() {
        let property = {
            property: {
                contactIsVisible: false,
                ownerId: currentUser,
                price: formatPrice(),
                active: true,
                likedBy: [],
                acceptsExchange,
                bathroom: parseInt(bathroomQuantity),
                bedroom: parseInt(bedroomQuantity),
                cep: CEP,
                city,
                complement,
                description,
                garage: parseInt(garageQuantity),
                images: getImages(),
                neightboard,
                number,
                otherItems: getSelectedInputs(), // verificar
                squareConstruction: parseInt(squareMeterConstruction),
                squareMeter: parseInt(squareMeter),
                state,
                street,
                type: selectboxValue
            }
        }
        navigate("/nossos-planos", {state: property})
    }

    async function nextToPayment() {
        const formData = new FormData();
        const data = {
            contactIsVisible: false,
            ownerId: currentUser,
            price: parseInt(price),
            active: true,
            likedBy: [],
            acceptsExchange,
            bathroom: parseInt(bathroomQuantity),
            bedroom: parseInt(bedroomQuantity),
            cep: CEP,
            city,
            complement,
            description,
            garage: parseInt(garageQuantity),
            images: getImages(),
            neightboard,
            number,
            otherItems: getSelectedInputs(), // verificar
            squareConstruction: parseInt(squareMeterConstruction),
            squareMeter: parseInt(squareMeter),
            state,
            street,
            type: selectboxValue
        }
        const { images, ...property} = data;

        if (images.length > 0) {
            const imagesUpload = images;
          
            const convertBlobToFile = async (blobUrl, index) => {
              const response = await fetch(blobUrl);
              const blob = await response.blob();
              
              const fileType = blob.type || 'image/jpeg'; 
              
              return new File([blob],`image-${index}.jpg`, { type: fileType });
            };
          
            const uploadImages = async () => {
              for (let i = 0; i < imagesUpload.length; i++) {
                const file = await convertBlobToFile(imagesUpload[i], i);
          
                formData.append(`file[${i}]`, file);
              }
            };
          
            await uploadImages();
        }
        formData.append("data", JSON.stringify(property))
        
            axios.post(
                "https://us-central1-alugueimoveis-6808c.cloudfunctions.net/addProperty", 
                formData
            ).then(result => {
                console.log(result)
                if (result.data.message === "Propriedade salva com sucesso") {
                    navigate("/painel-de-controle")
                }
            }).catch(e => {
                console.log(e)
            })
    }
  
    function getSelectedInputs() {
        return inputs.map((item) => {
            if (selectedInputs[item.id]) {
                return item
            }
        }).filter((item) => {
            if (item !== undefined) return item
        })
    }

    function formatPrice() {
        let tempPrice = price.split(",")[0].replace(/\./g, "");
        return parseInt(tempPrice);
    }

    const ImageUpload = ({ initialImages = [], images, setImages }) => {
        const handleAddImages = (event) => {
          const selectedFiles = Array.from(event.target.files);
          if (selectedFiles.length + images.length > 20) {
            alert("Você só pode adicionar até 20 imagens.");
            return;
          }
      
          const newImages = selectedFiles.map((file) => ({
            id: uuidv4(),
            url: URL.createObjectURL(file),
            file: file
          }));
          setImages(prevImages => [...prevImages, ...newImages]);
        };
      
        const handleRemoveImage = (id) => {
          setImages(prevImages => prevImages.filter(image => image.id !== id));
        };

        const Wrapper = styled.div`
            display: flex;
            justify-content: ${images.length === 0 ? "center" : "flex-start"};
            flex-wrap: wrap;
            gap: 10px;
            margin-top: 10px;

            @media (max-width: ${theme.breackpoint.mobile_2}) {
                
            }
            
            
        `
      
        return (
          <div className="image-selection" style={{ width: "100%" }}>
            <Wrapper>
              {images.map((image) => (
                <div
                  key={image.id}
                  style={{
                    position: 'relative',
                    width: isMobile ? "48%" : '24%',
                    paddingBottom: '24%',
                    overflow: 'hidden',
                    borderRadius: '5px',
                    backgroundImage: `url(${image.url})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                >
                  <span
                    onClick={() => handleRemoveImage(image.id)}
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      width: "24px",
                      height: "24px",
                      background: 'rgba(255, 0, 0, 0.8)',
                      color: 'white',
                      borderRadius: '50%',
                      cursor: 'pointer',
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <FiX />
                  </span>
                </div>
              ))}
              <label style={{
                display: images.length !== 20 ? "flex" : "none",
                alignSelf: "center",
                justifyContent: "center",
                width: '23%',
                background: theme.fontColor.green,
                color: 'white',
                borderRadius: '5px',
                cursor: 'pointer',
                position: 'relative',
              }}>
                <input
                  value={""}
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleAddImages}
                  disabled={images.length >= 20}
                  style={{
                    opacity: 0,
                    cursor: 'pointer',
                    width: '0%', // Para garantir que o input cubra todo o label
                    height: '0%' // Para garantir que o input cubra todo o label
                  }}
                />
                <span style={{
                  fontSize: '1.2rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}>
                  <span
                    style={{
                      padding: "5px 20px",
                      cursor: 'pointer',
                    }}
                  >
                    Carregar
                  </span>
                </span>
              </label>
            </Wrapper>
          </div>
        );
    };
      
    const inputs = [
        { id: "academia", label: "Academia" },
        { id: "aceita_pets", label: "Aceita Pets" },
        { id: "churrasqueira", label: "Churrasqueira" },
        { id: "piscina_aquecida", label: "Piscina Aquecida" },
        { id: "alarme", label: "Alarme" },
        { id: "closet", label: "Closet" },
        { id: "portao_eletrico", label: "Portão Elétrico" },
        { id: "aquecedor_solar", label: "Aquecedor Solar" },
        { id: "cozinha_planejada", label: "Cozinha Planejada" },
        { id: "sala_de_jantar", label: "Sala de Jantar" },
        { id: "ar_condicionado", label: "Ar Condicionado" },
        { id: "elevador", label: "Elevador" },
        { id: "varanda", label: "Varanda" },
        { id: "area_gourmet", label: "Área Gourmet" },
        { id: "energia_solar", label: "Energia Solar" },
        { id: "mobiliado", label: "Mobiliado" },
        { id: "area_de_serviço", label: "Área De Serviço" },
        { id: "escritorio", label: "Escritório" },
        { id: "seguranca_24h", label: "Segurança 24h" },
        { id: "armario_embutido", label: "Armário Embutido" },
        { id: "home", label: "Home" },
        { id: "permite_animais", label: "Permite Animais" },
        { id: "banheira", label: "Banheira" },
        { id: "interfone", label: "Interfone" },
        { id: "lavabo", label: "Lavabo" },
        { id: "cameras_de_seguranca", label: "Câmeras de Segurança" },
        { id: "lavanderia", label: "Lavanderia" },
        { id: "sala_de_tv", label: "Sala de Tv" },
        { id: "cerca_eletrica", label: "Cerca Elétrica" },
        { id: "piscina", label: "Piscina" },
    ];

    const options = [
        {option: "Apartamento"},
        {option: "Área Comercial"},
        {option: "Casa"},
        {option: "Casa Comercial"},
        {option: "Casa Em Condomínio"},
        {option: "Chácara"},
        {option: "Galpão/Armazém"},
        {option: "Kitnet/Studio"},
        {option: "Prédio Comercial"},
        {option: "Rancho"},
        {option: "Sala Comercial"},
        {option: "Salão Comercial"},
        {option: "Sítio/Fazenda"},
        {option: "Terreno"},
        {option: "Terreno Comercial"},
        {option: "Terreno Em Condomínio"},
    ]


    return <WrapperRegisterProperty step={step}>
        {step === 0 && 
        <>
            <h1 className="title_session">
                Fale um pouco sobre as <br />
                <span className="bold">especificações do seu imóvel.</span>
            </h1>
            <div className="specs_one">
                <div className="spec">
                    <span className="title">Selecione o tipo de imóvel</span>
                    <div className="select-box">
                        <div className="custom-selectbox">
                            <span className="value" onClick={() => setShowOptions(!showOptions)}>{selectboxValue}</span>
                            <div className={`options${showOptions ? " show" : ""}`}>
                                {options.map((option, index) => {
                                    return <span key={index+option.option} className={`option${selectboxValue === option.option ? " selected" : ""}`} onClick={() => handleSelectOption(option.option)}>{option.option}</span>
                                })}
                            </div>
                        </div>
                        <IoIosArrowDown className={showOptions && "actived"} onClick={() => setShowOptions(!showOptions)} />
                    </div>
                </div>
                <div className="spec">
                    <span className="title">Metragem Terreno</span>
                    <div className="inputbox t5">
                        <input type="text" value={squareMeter} onChange={e => setSquareMeter(e.currentTarget.value)} />
                        <span className="sufix">M²</span>
                    </div>
                </div>
                <div className="spec">
                    <span className="title">Metragem Construção</span>
                    <div className="inputbox t5">
                        <input type="text" value={squareMeterConstruction} onChange={e => setSquareMeterConstruction(e.currentTarget.value)}/>
                        <span className="sufix">M²</span>
                    </div>
                </div>
            </div>
            <div className="specs_two">
                <div className="spec">
                    <span className="title">Banheiros</span>
                    <div className="checkbox_select">
                        <span className="checkbox" onClick={(e) => setBathroomQuantityByCheckbox("1", e)}>1</span>
                        <span className="checkbox" onClick={(e) => setBathroomQuantityByCheckbox("2", e)}>2</span>
                        <span className="checkbox" onClick={(e) => setBathroomQuantityByCheckbox("3", e)}>3</span>
                        <span className="checkbox" onClick={(e) => setBathroomQuantityByCheckbox("4", e)}>4</span>
                        <span className="checkbox" onClick={(e) => setBathroomQuantityByCheckbox("4+", e)}>4+</span>
                    </div>
                </div>
                <div className="spec">
                    <span className="title">Quartos</span>
                    <div className="checkbox_select">
                        <span className="checkbox" onClick={(e) => setBedroomQuantityByCheckbox("1", e)}>1</span>
                        <span className="checkbox" onClick={(e) => setBedroomQuantityByCheckbox("2", e)}>2</span>
                        <span className="checkbox" onClick={(e) => setBedroomQuantityByCheckbox("3", e)}>3</span>
                        <span className="checkbox" onClick={(e) => setBedroomQuantityByCheckbox("4", e)}>4</span>
                        <span className="checkbox" onClick={(e) => setBedroomQuantityByCheckbox("4+", e)}>4+</span>
                    </div>
                </div>
                <div className="spec">
                    <span className="title">Vagas Garagem</span>
                    <div className="checkbox_select">
                        <span className="checkbox" onClick={(e) => setGarageQuantityByCheckbox("1", e)}>1</span>
                        <span className="checkbox" onClick={(e) => setGarageQuantityByCheckbox("2", e)}>2</span>
                        <span className="checkbox" onClick={(e) => setGarageQuantityByCheckbox("3", e)}>3</span>
                        <span className="checkbox" onClick={(e) => setGarageQuantityByCheckbox("4", e)}>4</span>
                        <span className="checkbox" onClick={(e) => setGarageQuantityByCheckbox("4+", e)}>4+</span>
                    </div>
                </div>
            </div>
            <div className="specs_three">
                <div className="spec">
                    <span className="title">Valor do Imóvel</span>
                    <div className="inputbox">
                        <span className="prefix">R$</span>
                        <input type="text" value={price} onChange={handlePrice} />
                    </div>
                </div>
                {selectboxValue === "Apartamento" && 
                    <>
                        <div className="spec special">
                            <span className="title">Condomínio</span>
                            <div className="inputbox">
                                <span className="prefix">R$</span>
                                <input type="text" placeholder="5.000,00" value={condominiumPrice} onChange={e => setCondominiumPrice(e.currentTarget.value)} />
                            </div>
                        </div>
                        <div className="spec special">
                            <span className="title">IPTU</span>
                            <div className="inputbox">
                                <span className="prefix">R$</span>
                                <input type="text" placeholder="5.000,00" value={IPTU} onChange={e => setIPTU(e.currentTarget.value)} />
                            </div>
                        </div>
                    </>
                }
                <div className="spec">
                    <span className="title">Aceita Permuta</span>
                    <div className="buttons">
                        <span 
                            className={`button ${acceptsExchange ? "actived" : ""}`}
                            onClick={() => setAcceptsExchange(!acceptsExchange)}
                        >
                            Sim</span>
                        <span 
                            className={`button ${!acceptsExchange ? "actived" : ""}`}
                            onClick={() => setAcceptsExchange(!acceptsExchange)}  
                        >
                            Não
                        </span>
                    </div>
                </div>
            </div>
            <div className="specs_four">
                {inputs.map((input) => (
                    <div key={input.id} className="spec">
                        <input
                            type="radio"
                            id={input.id}
                            checked={!!selectedInputs[input.id]}
                            onClick={() => handleToggle(input.id)}
                            readOnly
                        />
                        <label htmlFor={input.id}>{input.label}</label>
                    </div>
                ))}
            </div>
            <div className="specs_five">
                <span className="title">Descreva seu imóvel</span>
                <textarea name="" id="" value={description} onChange={e => setDescription(e.currentTarget.value)}></textarea>
            </div>
            <div className="specs_six">
                <div className="spec">
                    <span className="title">CEP</span>
                    <input type="text" value={CEP} onChange={e => handleCepBlur(e.currentTarget.value)} />
                </div>
                <div className="spec">
                    <span className="title">Cidade</span>
                    <input type="text" className="s" value={city} disabled />
                </div>
                <div className="spec">
                    <span className="title">Endereço</span>
                    <input type="text" className="s" value={street} disabled />
                </div>
                <div className="spec">
                    <span className="title">Bairro</span>
                    <input type="text" className="s" value={neightboard} disabled />
                </div>
                <div className="spec">
                    <span className="title">Estado</span>
                    <input type="text" className="s" value={state} disabled />
                </div>
                <div className="spec">
                    <span className="title">Número/Complemento</span>
                    <div className="input-container">
                        <input type="text" value={number} onChange={e => setNumber(e.currentTarget.value)} />
                        <input type="text" value={complement} onChange={e => setComplement(e.currentTarget.value)} />
                    </div>
                </div>
            </div>
            <div className="specs_seven">
                <span className="title">Adicione fotos do seu imóvel</span>
                <span className="description title">*Até 20 Imagens</span>
                <ImageUpload images={images} setImages={setImages} />
                <span className="description">Seus dados são mantidos em sigilo por Alugue Imóveis e não são compartilhados com empresas de serviço similares.</span>
            </div>
            <div className="next-container">
                <div className="description" onClick={() => setCheckedPrivacyPolicy(!checkedPrivacyPolicy)}>
                    <div className={`checkbox${checkedPrivacyPolicy ? " checked" : " unchecked"}`}>
                        <FaCheck />
                    </div>
                    Ao enviar um anúncio, você está de acordo com nossa Política de Privacidade.
                </div>
                {handleAllDataFilledAndPrivacyPolice()  ? 
                    <span className="button finish" onClick={next}>
                        Avançar
                    </span>
                    :
                    <span className="button deactive">
                        Avançar
                    </span>
            }
            </div>
        </>
        }
        {step === 1 &&
            <>
                
            </>
        }
    </WrapperRegisterProperty>
}

export default RegisterPropertyPage