import styled from "styled-components";
import theme from "../../theme";

const WrapperBBanner = styled.div`
    width: calc(100% - 45%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 80px 22.5%;
    margin-top: 95px;
    display: flex;
    cursor: pointer;

    img {
        width: 100%;
    }

    @media (max-width: ${theme.breackpoint.mobile_2}) {
        display: none;
    }
    
    @media (max-width: ${theme.breackpoint.tabletsAndSmallScreens}) and (min-width: ${theme.breackpoint.mobile_2}) {
        width: calc(100% - 26%);
        margin: 80px 13%;
    }
    
    @media (max-width: ${theme.breackpoint.commonScreensAndLaptops}) and (min-width: ${theme.breackpoint.tabletsAndSmallScreens}) {
        width: calc(100% - 32%);
        margin: 80px 16%;
    }
    
    @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.commonScreensAndLaptops}) {
        width: calc(100% - 36%);
        margin: 80px 18%;
    }
    
    @media (max-width: ${theme.breackpoint.bigScreens}) and (min-width: ${theme.breackpoint.largeScreens}) {
        
    }
    
    
`

export default WrapperBBanner