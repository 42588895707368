import { useContext, useEffect, useRef, useState } from "react";
import NavBar from "../../components/nav-bar/NavBar";
import WrapperMyData from "./MyData.styles";
import { AuthContext } from './../../context/AuthContext';
import { updateUserInfo } from "../../hooks/firebase";


const MyData = () => {
    const {currentUser, userData, reloadUserData} = useContext(AuthContext)
    const [name, setName] = useState("")
    const [telephone, setTelephone] = useState("")

    useEffect(() => {
        let isMounted = true;
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // Para um efeito de scroll suave
            });
        };

        scrollToTop();

        setName(userData?.name)
        setTelephone(userData?.telephone)

        return () => {
            isMounted = false;
        };
    },[userData])

    function save () {
        let data = {
            uid: currentUser,
            name: name,
            telephone: telephone,
        }

        updateUserInfo(data).then(() => {
            reloadUserData()
        })
    }

    return <WrapperMyData>
        <NavBar />
        <div className="container-a">
            <div className="line">
                <span className="title">Nome Completo</span>
                <input type="text" value={name} onChange={(e) => setName(e.currentTarget.value)} />
            </div>
            <div className="line">
                <span className="title">Telefone/Whatsapp</span>
                <input type="text" value={telephone} onChange={(e) => setTelephone(e.currentTarget.value)} />
            </div>
            <span className="button" onClick={save}>
                Salvar
            </span>
        </div>
    </WrapperMyData>
}

export default MyData