import WrapperHouseAdvertisementSession from "./HouseAdvertisementSession-styles";
import Img from "../../imgs/categories/Batipin-Flat-by-studioWOK-03.jpg";
import { useEffect, useState } from "react";
import Carousel from "../slide_show/SlideShow";
import theme from "../../theme";
import { getPropertyList } from "../../hooks/firebase";

const HouseAdvertisementSession = (props) => {
    const [data, setData] = useState(null);
    const [slidesPerView, setSlidesPerView] = useState(4);
    
    function formatToBRL(number) {
        return number.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });
    }
 
    useEffect(() => {

        if (data === null) {
            getPropertyList().then(result => {
                console.log(result)
                let tempData = result.map(property => {
                    return {
                        img: property.images[0],
                        uri: `/imovel/${property.id}`,
                        title: `${property.street} ${property.number}`,
                        road: "",
                        square_meter: property.squareMeter,
                        bedrooms: property.bedroom,
                        showers: property.bathroom,
                        garage: property.garage,
                        base_price: formatToBRL(property.price),
                        price: "",
                    }
                })
                setData(tempData)
            })
        }

        const updateSlidesPerView = () => {
            const windowWidth = window.innerWidth;
            if (windowWidth <= theme.breackpoint.mobile_2.replace("px", "")) {
                setSlidesPerView(1)
            } else if (windowWidth <= theme.breackpoint.tabletsAndSmallScreens.replace("px", "")) {
                setSlidesPerView(2)
            } else {
                setSlidesPerView(4)
            }

        };
 
        updateSlidesPerView();
        window.addEventListener('resize', updateSlidesPerView);

        return () => {
            window.removeEventListener('resize', updateSlidesPerView);
        };
    }, []);

    return (
        <WrapperHouseAdvertisementSession>
            <span className="title">{props.title}</span>
            {data && (
                <Carousel
                    items={data.map((advertisement) => ({
                        img: advertisement.img,
                        uri: advertisement.uri,
                        title: advertisement.title,
                        road: advertisement.road,
                        square_meter: advertisement.square_meter,
                        bedrooms: advertisement.bedrooms,
                        showers: advertisement.showers,
                        garage: advertisement.garage,
                        base_price: advertisement.base_price,
                        price: advertisement.price,
                    }))}
                    visibleItems={slidesPerView}
                />
            )}
        </WrapperHouseAdvertisementSession>
    );
};

export default HouseAdvertisementSession;
