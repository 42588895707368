import { useRef } from "react";
import WrapperMyData from "../../views/my-data/MyData.styles";
import NavBar from "../nav-bar/NavBar";
import { changePassword } from "../../hooks/firebase"; // Import da função

const MyChangePassword = () => {
    const inputARef = useRef(null);
    const inputBRef = useRef(null);
    const inputCRef = useRef(null);

    function switchInputMode(ref) {
        let type = ref.current.getAttribute("type");

        if (type === "text") {
            ref.current.setAttribute("type", "password");
        } else {
            ref.current.setAttribute("type", "text");
        }
    }

    async function handleChangePassword() {
        const currentPassword = inputARef.current.value.trim();
        const newPassword = inputBRef.current.value.trim();
        const confirmPassword = inputCRef.current.value.trim();

        if (!currentPassword || !newPassword || !confirmPassword) {
            alert("Por favor, preencha todos os campos.");
            return;
        }

        if (newPassword !== confirmPassword) {
            alert("A nova senha e a confirmação não coincidem.");
            return;
        }

        try {
            const message = await changePassword(currentPassword, newPassword);
            alert(message); // Mensagem de sucesso
            inputARef.current.value = "";
            inputBRef.current.value = "";
            inputCRef.current.value = "";
        } catch (error) {
            alert(error.message); // Mensagem de erro
        }
    }

    return (
        <WrapperMyData>
            <NavBar />
            <div className="container-change-password">
                <div className="line">
                    <span className="title">Senha Atual:</span>
                    <div className="input-box">
                        <input type="password" ref={inputARef} />
                        <span className="sufix" onClick={() => switchInputMode(inputARef)}>Mostrar</span>
                    </div>
                </div>
                <div className="line">
                    <span className="title">Nova Senha:</span>
                    <div className="input-box">
                        <input type="password" ref={inputBRef} />
                        <span className="sufix" onClick={() => switchInputMode(inputBRef)}>Mostrar</span>
                    </div>
                </div>
                <div className="line">
                    <span className="title">Confirme a Nova Senha:</span>
                    <div className="input-box">
                        <input type="password" ref={inputCRef} />
                        <span className="sufix" onClick={() => switchInputMode(inputCRef)}>Mostrar</span>
                    </div>
                </div>
                <span className="button" onClick={handleChangePassword}>
                    Salvar
                </span>
            </div>
        </WrapperMyData>
    );
};

export default MyChangePassword;
