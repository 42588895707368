import styled from "styled-components";
import theme from "../../theme";

const WrapperMyProperty = styled.div`   
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 20px;

    .img-container {
        width: 25%;
        height: 200px;
        cursor: pointer;
        overflow: hidden; /* Garante que a imagem não ultrapasse o contêiner */
        position: relative; 
        border-radius: 20px;
        margin-bottom: 20px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover; /* Garante que a imagem preencha o contêiner sem distorção */
            border-radius: 20px;
            display: block;
        }
    }

    .body {
        width: 74%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        position: relative;

        .price {
            font-size: 1rem;
            font-family: ${theme.fontFamily.medium};
        }

        .location {
            font-size: 1rem;
            width: 80%;
            font-family: ${theme.fontFamily.regular};
        }

        .infos {
            width: 70%;
            display: flex;
            justify-content: space-between;

            .info {
                display: flex;
                align-items: center;
                gap: 5px;
                font-size: .9rem;
            }
        }

        .actions {
            display: flex;
            margin-top: 10px;
            font-family: ${theme.fontFamily.regular};

            .button {
                color: white;
                padding: 0px 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100px;
                cursor: pointer;
                font-size: .8rem;
                
                &.delete{
                    background-color: #e12e2c;
                    margin-right: 10px;
                }

                &.edit{
                    background-color: ${theme.fontColor.green};
                    margin-right: 10px;
                }

                &.turbo{
                    background-color: ${theme.fontColor.green};
                    margin-right: 20px;
                }
            }

            .active-switch-container {
                display: flex;
                align-items: center;
                gap: 5px;
                font-size: .8rem;
                align-self: flex-end;
            }
        }

        .button-remove {
            position: absolute;
            right: 20px;
            font-size: 1.8rem;
            color: #01bbb6;
            cursor: pointer;
        }
    }

    .confirmDelete {
        position: absolute;
        margin-bottom: 20px; 
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        background-color: white;
        border: 1px solid gray;
        border-radius: 10px;
        padding: 10px;
        
        .top {
            width: 90%;
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;

            span {
                font-size: .95rem;
            }

            .info {
                font-size: .8rem;
            }
        }

        .action {
            color: #e12e2c;
            width: 5%;
            cursor: pointer;
        }

        .button {
            color: white;
            padding: 7px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100px;
            cursor: pointer;
            font-size: .8rem;
            background-color: #e12e2c;
        }
    }
    
    @media (max-width: ${theme.breackpoint.tabletsAndSmallScreens}) {
        flex-direction: column;

        .img-container {
            width: 100%;
            height: 260px;

            img {
                height: auto;
                height: 260px;
            }
        }

        .body {
            width: 100%;

            .location {
                width: 100%;
            }

            .infos {
                width: 100%;
            }

            .actions {

                .button {
                    
                    padding: 5px 12px;
                }

            }
        }
    }
    
    @media (max-width: ${theme.breackpoint.commonScreensAndLaptops}) and (min-width: ${theme.breackpoint.tabletsAndSmallScreens}) {
        .img-container {
            height: 170px;
        }

        .body{
            .price, location, .infos .info {
                font-size: .85rem;
            }

            
        }
    }
    
    @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.commonScreensAndLaptops}) {
        .img-container {
            height: 170px;
        }

        .body{
            .price, location, .infos .info {
                font-size: .85rem;
            }


        }
    }
    
    @media (max-width: ${theme.breackpoint.bigScreens}) and (min-width: ${theme.breackpoint.largeScreens}) {
        
    }
    
`

export default WrapperMyProperty