import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { auth, db } from "./firebase";

const createAccount = async (email, password, userData) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    const uid = user.uid;
    const userDocRef = doc(db, "users", uid);

    const userFirestoreData = {
      uid,
      photoURL: "",
      creci: userData.creci || "",
      type: userData.type || "",      
      document: userData.document || "", 
      name: userData.name || "",       
      email: user.email,               
      telephone: userData.telephone || "", 
      newsletter: false,
      favorites: [],
      properties: userData.properties || [],
      notifications_enabled: true,
    };

    // Salva os dados no Firestore
    await setDoc(userDocRef, userFirestoreData);
    return true;
  } catch (error) {
    console.error("Erro ao criar usuário e salvar dados:", error);
  }
};

export default createAccount;