import WrapperCreateAccount from "./CreateAccount-styles"
import IMG from "../../imgs/young-happy-couple-receiving-keys-their-new-home-from-real-estate-agent.jpg"
import Logo from "../../imgs/ALUGUE IMÓVEIS.png"
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import createAccount from "../../hooks/CreateAccount";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../hooks/firebase";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

const CreateAccountPage = () => {
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false)
    const feedbackIsValidDocument = useRef(null)
    const feedbackInputEmail = useRef(null)
    const [formPage, setFormPage] = useState(1)
    const [type, setType] = useState(null)
    const [document, setDocument] = useState(null)
    const [creci, setCreci] = useState(null)
    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [telephone, setTelephone] = useState(null)
    const [isValid, setIsValid] = useState({
        name: false,
        type: false,
        document: false,
        creci: false,
        email: false,
        password: false,
        telefone: false
    })
    const inputDocumentLength = 18
    const inputNameLength = 50

    const handleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handle = (href) => {
        navigate(href)
    }

    useEffect(() => {
        setType("CPF")
    },[])

    const handleSetValueToTrue = (key, value) => {
        setIsValid((prevState) => ({
            ...prevState,
            [key]: value
        }));
    }

    const handleTypeOfAccount = (e, type) => {
        let currentElement = e.currentTarget;
        let parentElement = currentElement.parentElement;
    
        parentElement.querySelectorAll(".option").forEach(item => {
            item.querySelector(".circle").classList.remove("active");
        });
    
        currentElement.querySelector(".circle").classList.add("active");
        setType(type);
        setDocument("")
    }

    const nextFormPage = () => {
        if (name !== undefined && 
            email !== undefined && 
            password !== undefined && 
            telephone !== undefined
        ){
            setFormPage(2)
        }
    }

    function areAllFieldsFilled(data) {
        return Object.entries(data).every(([key, value]) => {
            if (key === 'creci' && data.type === 'CPF') return true;
            if (key === 'password' && value.length < 8) return false;
            
            return value !== null && value !== undefined && value !== '';
        });
    }

    async function isDocumentExists(document) {
        const userCollection = collection(db, 'users');
        const q = query(userCollection, where('document', '==', document));
        const querySnapshot = await getDocs(q);
        
        return !querySnapshot.empty;
    }

    function formatPhoneNumber(value) {
        const cleanValue = value.replace(/\D/g, '');
        if (cleanValue.length === 0) return '';
    
        if (cleanValue.length <= 2) {
            return `(${cleanValue}`;
        } else if (cleanValue.length <= 6) {
            return `(${cleanValue.slice(0, 2)}) ${cleanValue.slice(2)}`;
        } else if (cleanValue.length <= 10) {
            return `(${cleanValue.slice(0, 2)}) ${cleanValue.slice(2, 6)}-${cleanValue.slice(6)}`;
        } else {
            return `(${cleanValue.slice(0, 2)}) ${cleanValue.slice(2, 7)}-${cleanValue.slice(7, 11)}`;
        }
    }

    function handleSetTelephone (event) {
        let temp = formatPhoneNumber(event.currentTarget.value)
        setTelephone(temp)
    }

    const handleCreateAccount = async () => {
        let data = {
            type,
            document,
            creci,
            name,
            email,
            telephone,
            password
        }
        if (areAllFieldsFilled(data)) {
            isDocumentExists(data.document).then(async result => {
                if (!result) {
                    let result = await createAccount(email, password, data)
                    if (result) {
                        handle("/")
                    }
                }
            })
        }
    }

    function formatCPFOrCNPJ(value) {
        const cleanValue = value.replace(/\D/g, '');
    
        if (cleanValue.length <= 11) {
            if (cleanValue.length <= 2) return cleanValue;
            if (cleanValue.length <= 5) return `${cleanValue.slice(0, 3)}.${cleanValue.slice(3)}`;
            if (cleanValue.length <= 8) return `${cleanValue.slice(0, 3)}.${cleanValue.slice(3, 6)}.${cleanValue.slice(6)}`;
            return `${cleanValue.slice(0, 3)}.${cleanValue.slice(3, 6)}.${cleanValue.slice(6, 9)}-${cleanValue.slice(9, 11)}`;
        }
    
        if (cleanValue.length <= 14) {
            if (cleanValue.length <= 2) return cleanValue;
            if (cleanValue.length <= 5) return `${cleanValue.slice(0, 2)}.${cleanValue.slice(2)}`;
            if (cleanValue.length <= 8) return `${cleanValue.slice(0, 2)}.${cleanValue.slice(2, 5)}.${cleanValue.slice(5)}`;
            if (cleanValue.length <= 12) return `${cleanValue.slice(0, 2)}.${cleanValue.slice(2, 5)}.${cleanValue.slice(5, 8)}/${cleanValue.slice(8)}`;
            return `${cleanValue.slice(0, 2)}.${cleanValue.slice(2, 5)}.${cleanValue.slice(5, 8)}/${cleanValue.slice(8, 12)}-${cleanValue.slice(12, 14)}`;
        }
    
        return cleanValue;
    }

    function isValidDocument() {
        let tempDocument = document.replace(/\D/g, '');
      
        function isValidCPF(cpf) {
          if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;
          let sum = 0;
          for (let i = 0; i < 9; i++) sum += parseInt(cpf[i]) * (10 - i);
          let firstVerifier = (sum * 10) % 11;
          if (firstVerifier === 10 || firstVerifier === 11) firstVerifier = 0;
          if (firstVerifier !== parseInt(cpf[9])) return false;
          sum = 0;
          for (let i = 0; i < 10; i++) sum += parseInt(cpf[i]) * (11 - i);
          let secondVerifier = (sum * 10) % 11;
          if (secondVerifier === 10 || secondVerifier === 11) secondVerifier = 0;
          return secondVerifier === parseInt(cpf[10]);
        }
      
        // Função para verificar CNPJ
        function isValidCNPJ(cnpj) {
          if (cnpj.length !== 14 || /^(\d)\1+$/.test(cnpj)) return false;
          let sum = 0;
          const firstWeights = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
          for (let i = 0; i < 12; i++) sum += parseInt(cnpj[i]) * firstWeights[i];
          let firstVerifier = sum % 11 < 2 ? 0 : 11 - (sum % 11);
          if (firstVerifier !== parseInt(cnpj[12])) return false;
          sum = 0;
          const secondWeights = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
          for (let i = 0; i < 13; i++) sum += parseInt(cnpj[i]) * secondWeights[i];
          let secondVerifier = sum % 11 < 2 ? 0 : 11 - (sum % 11);
          return secondVerifier === parseInt(cnpj[13]);
        }
    
        if (tempDocument.length === 11) {
          return isValidCPF(tempDocument);
        } else if (tempDocument.length === 14) {
          return isValidCNPJ(tempDocument);
        } else {
          return false;
        }
    }

    function handleIsValidDocument() {
        let isValid = isValidDocument()
        console.log(isValid)
        if (!isValid) {
            feedbackIsValidDocument.current.innerText = "O Documento informado é invalido!"
        } else {
            feedbackIsValidDocument.current.innerText = ""
        }
    }

    function handleSetCPF(event) {
        const inputValue = event.target.value;
        const isDeleting = inputValue.length < document?.length;
    
        if (!isDeleting) {
            const formattedValue = formatCPFOrCNPJ(inputValue);
            setDocument(formattedValue);
        } else {
            setDocument(inputValue);
        }
    }

    const handleIsValidEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(email);

        if (!isValid) {
            handleSetValueToTrue("email", false)
            feedbackInputEmail.current.innerText = "Informe um email válido!"
        } else {
            handleSetValueToTrue("email", true)
            feedbackInputEmail.current.innerText = ""
        }
        
    }

    return <WrapperCreateAccount>
        <div className="ilustration">
            <img src={IMG} alt="" />
        </div>
        <div className="form">
            <img className="logo" src={Logo} alt="" /> 
            <div className="about">
                <span>
                    Ao me cadastrar, aceito os <a href="#" className="bold">Termos de uso</a> e <a href="#" className="bold">Politicas de privacidade</a> do Alugue Imóveis, receber comunicações do Alugue Imóveis e afirmo ter 18 anos ou mais.
                </span>
            </div> 
            <div className="separator">
                <span>
                    ou cadastre com seu e-mail
                </span>
            </div>
            {formPage === 1 &&
                <>
                    <div className="form-item">
                        <span>Cadastrar como:</span>
                        <div className="option" onClick={(e) => handleTypeOfAccount(e, "CPF")}>
                            <span className="circle active"></span>
                            <span className="title">Pessoa Física</span>
                        </div>
                        <div className="option" onClick={(e) => handleTypeOfAccount(e, "CNPJ")}>
                            <span className="circle"></span>
                            <span className="title">Pessoa Jurídica</span>
                        </div>
                        <div className="option" onClick={(e) => handleTypeOfAccount(e, "Imobiliária")}>
                            <span className="circle"></span>
                            <span className="title">Imobiliária</span>
                        </div>
                    </div>
                    <div className="form-item">
                        <span>{type !== "CPF" ? "Razão Social":  "Nome e sobrenome"}</span>
                        <input type="text" onChange={(e) => setName(e.currentTarget.value)} maxLength={inputNameLength} />
                    </div>
                    <div className="form-item">
                        <span>{type !== "CPF" ? "CNPJ":  "CPF"}</span>
                        <input type="text" value={document} onBlur={handleIsValidDocument} onChange={handleSetCPF} maxLength={type === "CPF" ? 14 : 18 } />
                        <span className="feedback" ref={feedbackIsValidDocument}></span>
                    </div>
                    {type === "Imobiliaria" && 
                        <div className="form-item">
                            <span>Creci</span>
                            <input type="text" onChange={(e) => setCreci(e.currentTarget.value)} />
                        </div>
                    }
                    <span className="button next" onClick={nextFormPage}>
                        Próximo
                    </span>
                </>
            }
            {formPage === 2 &&
                <>
                    <div className="form-item">
                        <span>E-mail</span>
                        <input type="text" onBlur={handleIsValidEmail} onChange={(e) => setEmail(e.currentTarget.value)} />
                        <span className="feedback" ref={feedbackInputEmail}></span>
                    </div>
                    <div className="form-item">
                        <span>Senha (mínimo 8 caracteres)</span>
                        <div className="inputbox">
                            <input type={showPassword ? "text" : "password"} onChange={(e) => setPassword(e.currentTarget.value)} />
                            {showPassword ? 
                                <FaRegEyeSlash onClick={handleShowPassword} />
                            :
                                <FaRegEye onClick={handleShowPassword} />
                            }
                        </div>
                    </div>
                    <div className="form-item">
                        <span>Telefone (opicional)</span>
                        <input type="text" value={telephone} onChange={handleSetTelephone} />
                    </div>
                    <span className="button next" onClick={() => handleCreateAccount()}>
                        Cadastrar
                    </span>
                </>
            }
            <span className="have-account">
                Já possui uma conta? <strong onClick={() => handle("/entrar")}>Entre aqui</strong>
            </span>
        </div>
    </WrapperCreateAccount>
}

export default CreateAccountPage