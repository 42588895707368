import WrapperHeaderMobile from "./HeaderMobile.styles";
import { IoHome } from "react-icons/io5";
import { IoSearch } from "react-icons/io5";
import { GoPlusCircle } from "react-icons/go";
import { IoChatbubbleOutline } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowRightToBracket } from "react-icons/fa6";
import { SlArrowRight } from "react-icons/sl";
import { FaBlog } from "react-icons/fa";
import { BsHouse } from "react-icons/bs";
import { IoMdSpeedometer } from "react-icons/io";
import { FaRegHeart } from "react-icons/fa";
import { CiText } from "react-icons/ci";
import { FaRegClipboard } from "react-icons/fa";
import { BsBoxArrowLeft } from "react-icons/bs";
import { getAuth, signOut } from "firebase/auth";
import { useContext, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { doc, updateDoc } from "firebase/firestore";
import { db, uploadProfileImage } from "../../hooks/firebase";

const HeaderMobile = () => {
    const navigate = useNavigate();
    const { currentUser, userData, setUserData } = useContext(AuthContext);
    const [show, setShow] = useState(false);
    const fileInputRef = useRef(null);

    const logout = () => {
        const auth = getAuth();
        try {
            signOut(auth);
            setShow(!show);
            navigate("/entrar");
        } catch (error) {
            console.error("Erro ao deslogar: ", error);
        }
    };

    const handleToggle = () => {
        setShow(!show);
    };

    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            uploadProfileImage(currentUser, file).then((result) => {
                if (result) {
                    const userRef = doc(db, "users", currentUser);
                    updateDoc(userRef, { photoURL: result })
                        .then(() => {
                            setUserData((prevUserData) => ({
                                ...prevUserData,
                                photoURL: result,
                            }));
                        })
                        .catch((error) =>
                            console.error("Erro ao atualizar Firestore:", error)
                        );
                } else {
                    console.error("Falha ao atualizar a imagem de perfil.");
                }
            });
        }
    };

    return (
        <WrapperHeaderMobile>
            <div className={`top${show ? " show" : ""}`}>
                {userData && (
                    <div className="user">
                        {!userData.photoURL ? (
                            <div className="user-img" onClick={handleImageClick}>
                                <FaArrowRightToBracket />
                            </div>
                        ) : (
                            <img
                                src={userData.photoURL}
                                alt="Imagem de perfil"
                                onClick={handleImageClick}
                            />
                        )}
                        <span className="name">{userData?.name}</span>

                        {/* Input para upload de imagem */}
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            accept="image/*"
                            onChange={handleFileChange}
                        />
                    </div>
                )}
                {!userData && (
                    <Link to={"/entrar"} onClick={handleToggle}>
                        <div className="info">
                            <FaArrowRightToBracket />
                            Entrar
                        </div>
                        <SlArrowRight />
                    </Link>
                )}
                <Link to={"/blog"} onClick={handleToggle}>
                    <div className="info">
                        <FaBlog />
                        Blog
                    </div>
                    <SlArrowRight />
                </Link>
                {userData && (
                    <Link to={"/meus-imoveis"} onClick={handleToggle}>
                        <div className="info">
                            <BsHouse />
                            Meus Imóveis
                        </div>
                        <SlArrowRight />
                    </Link>
                )}
                {userData && (
                    <Link to={"/notificacoes"} onClick={handleToggle}>
                        <div className="info">
                            <IoChatbubbleOutline />
                            Notificações
                        </div>
                        <SlArrowRight />
                    </Link>
                )}
                <Link to={"/nossos-planos"} onClick={handleToggle}>
                    <div className="info">
                        <IoMdSpeedometer />
                        Turbinar
                    </div>
                    <SlArrowRight />
                </Link>
                {userData && (
                    <Link to={"/meus-imoveis-favoritos"} onClick={handleToggle}>
                        <div className="info">
                            <FaRegHeart />
                            Favoritos
                        </div>
                        <SlArrowRight />
                    </Link>
                )}
                <Link to={"/institucional"} onClick={handleToggle}>
                    <div className="info">
                        <CiText />
                        Institucional
                    </div>
                    <SlArrowRight />
                </Link>
                <Link to={"/meus-termos-de-uso"} onClick={handleToggle}>
                    <div className="info">
                        <FaRegClipboard />
                        Termos de uso
                    </div>
                    <SlArrowRight />
                </Link>
                {userData && (
                    <a href onClick={logout}>
                        <div className="info">
                            <BsBoxArrowLeft />
                            Sair
                        </div>
                        <SlArrowRight />
                    </a>
                )}
            </div>
            <div className="bottom">
                <Link to="/" className="item" onClick={() => setShow(false)}>
                    <IoHome />
                    <span>Início</span>
                </Link>
                <Link to="/buscador" className="item" onClick={() => setShow(false)}>
                    <IoSearch />
                    <span>Buscar</span>
                </Link>
                <Link to="/anunciar" className="item" onClick={() => setShow(false)}>
                    <GoPlusCircle />
                    <span>Anunciar</span>
                </Link>
                <Link to="/meus-chats" className="item" onClick={() => setShow(false)}>
                    <IoChatbubbleOutline />
                    <span>Mensagem</span>
                </Link>
                <div className="item" onClick={() => setShow(!show)}>
                    <RxHamburgerMenu />
                    <span>Menu</span>
                </div>
            </div>
        </WrapperHeaderMobile>
    );
};

export default HeaderMobile;
