import WrapperBBanner from "./Styles-BBanner";
import Banner07 from "../../imgs/banners/BANNER 07.png"
import { useNavigate } from 'react-router-dom';


const BBanner = () => {
    const navigate = useNavigate()

    const handleClick = () => {
        navigate("/anunciar")
    }
  
    return <WrapperBBanner>
        <img src={Banner07} alt="" onClick={handleClick} />
        {/*
        <div className="wrapper">
            <div className="banner">
                <img src={LookWeBanner} alt="look-we-banner" />
                <div className="gradient"></div>
            </div>
            <div className="content">
                <span className="top">
                    Faça agora o <br /> seu anúncio,
                </span>
                <span className="middle">
                    FÁCIL E RÁPIDO
                </span>
                <a className="button" href="g">
                    Anunciar
                </a>
            </div>
        </div>  
        */}
    </WrapperBBanner>
}

export default BBanner