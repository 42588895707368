import { useEffect, useState } from "react";
import Plan from "../../components/plan/Plan";
import WrapperPlans from "./Styles-Plans";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../hooks/firebase";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const Plans = props => {
    const [data, setData] = useState(null)
    const location = useLocation()
    const navigate = useNavigate()
    const propertyData = (location.state) ? location.state.property : false

    
    async function fetchPlans() {
        const plansCollection = collection(db, "plans");
        const plansSnapshot = await getDocs(plansCollection);
        
        const plansList = plansSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        
        setData(plansList)
    }

    useEffect(() => {
        if (data === null) fetchPlans()
    },[])


    async function nextToCreate(planProperties){
        if (propertyData == false) return;
        const newData = {...propertyData, planId: planProperties.id}
        const formData = new FormData();
        const { images, ...property} = newData;

        if (images.length > 0) {
            const imagesUpload = images;
          
            const convertBlobToFile = async (blobUrl, index) => {
                const response = await fetch(blobUrl);
                const blob = await response.blob();
                const fileType = blob.type || 'image/jpeg'; 
                
                return new File([blob],`image-${index}.jpg`, { type: fileType });
            };
          
            const uploadImages = async () => {
              for (let i = 0; i < imagesUpload.length; i++) {
                const file = await convertBlobToFile(imagesUpload[i], i);
          
                formData.append(`file[${i}]`, file);
              }
            };
          
            await uploadImages();
        }
        
        formData.append("data", JSON.stringify(property))
        axios.post(
            "https://us-central1-alugueimoveis-6808c.cloudfunctions.net/addProperty", 
            formData
        ).then(result => {
            if (result.data.message === "Propriedade salva com sucesso") {
                console.log({"resultado-da-requisição": result, "imovel": property})
                const returnData = result.data.data
                const ownerId = returnData.ownerId
                const propertyId = returnData.id
                const planId = planProperties.id

                if(planProperties.name === "Grátis") {
                    navigate(`/imovel/${result.data.data.id}`)
                } else {
                    navigate(`/pagamento/${propertyId}/${planId}/${ownerId}`)
                }
            } 
        }).catch(e => {
            console.log(e)
        })
    }
 
    return <WrapperPlans>
        <h1>Nossos Planos</h1>
        <h3> <span className="bold">Quer vernder mais rápido?</span> Destaque seu anúncio.</h3>
        <div className="plans">
            {data && data.map((plan, index) => {
                if (plan.order === undefined) return <></>
                return <Plan
                    key={index+"plan"}
                    data={{...plan, containsData: propertyData, setPlan: nextToCreate}}
                />
            })}
        </div> 
    </WrapperPlans>  
}

export default Plans