import styled from "styled-components";
import theme from "../../theme";

const WrapperMyData = styled.div`
    margin: 30px 13%;
    display: flex;
    justify-content: center;
    position: relative;
 
    .container-a{
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 40%;
        margin-left: 50px;
        margin-top: 50px;
        height: 60vh;

        .line {
            display: flex;
            flex-direction: column;
            gap: 4px;
            width: 100%;

            .title {
                font-size: 1.1rem;
                font-weight: 600;
                margin-left: 25px;
            }

            input {
                outline: none;
                border: 2px solid #bcbcbc;
                font-size: .915rem;
                padding: 8px 25px;
                width: calc(100% - 50px);
                border-radius: 100px;
            }
        }

        .button {
            margin-top: 20px;
            background-color: #01bbb6;
            align-self: flex-start;
            padding: 2px 30px;
            border-radius: 100px;
            font-size: 1.1rem;
            color: white;
            cursor: pointer;
        }

        .open-menu {
            display: none;
        }
    }

    .container-b{
        display: flex;
        flex-direction: column;
        gap: 7px;
        width: 55%;
        margin-left: 50px;
        margin-top: 50px;
        height: 60vh;

        .plan {
            font-size: 3rem;
            font-weight: 600;
            color: #01bbb6;
            font-family: ${theme.fontFamily.bold};
        }

        .line {
            display: flex;
            align-items: center;
            gap: 15px;
            margin-bottom: 10px;

            img {
                width: 25px;
            }

            &:nth-child(6) {
                margin-top: 30px;
                justify-content: flex-start;
                align-items: flex-start;
                font-weight: 600;
                color: rgb(60 ,60 ,60 );

                .bold {
                    margin-left: -7px;
                    font-weight: 600;
                    color: #01bbb6;
                }
            }
        }

        .button {
            margin-top: 30px;
            padding: 6px 40px;
            border-radius: 100px;
            font-size: .875rem;
            background-color: #01bbb6;
            align-self: flex-start;
            color: white;
            font-weight: 600;
        }
    }

    .container-c{
        display: flex;
        flex-direction: column;
        width: 55%; 
        gap: 20px;
        margin-left: 50px;
        overflow-y: auto;
        margin-top: 40px;
        height: 60vh;
        ${({ nothing }) => (nothing ? 'justify-content: center;' : '')}
        ${({ nothing }) => (nothing ? 'align-items: center;' : '')}

        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 5px;
        }

        .nothing {
            font-size: 1.1rem;
        }

        .search-box {
            width: 90%;
            display: flex;
            flex-direction: column;
            gap: 2px;

            .title {
                font-size: 1.1rem;
                font-weight: 600;
                margin-left: 20px;
            }

            input {
                outline: none;
                border: #888 1px solid;
                border-radius: 100px;
                padding: 7px 20px;
                font-size: 1.05rem;
            }
        }

        .properties {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        
    }

    .container-favorites{
        display: flex;
        flex-direction: column;
        width: 55%; 
        gap: 20px;
        margin-left: 50px;
        overflow-y: auto;
        margin-top: 40px;
        height: 60vh;
        ${({ nothing }) => (nothing ? 'justify-content: center;' : '')}
        ${({ nothing }) => (nothing ? 'align-items: center;' : '')}

        .nothing-favorites {
            font-size: 1.1rem;
        }

        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 5px;
        }

        .search-box {
            width: 90%;
            display: flex;
            flex-direction: column;
            gap: 2px;

            .title {
                font-size: 1.1rem;
                font-weight: 600;
                margin-left: 20px;
            }

            input {
                outline: none;
                border: #888 1px solid;
                border-radius: 100px;
                padding: 7px 20px;
                font-size: 1.05rem;
            }
        }

        .properties {
            display: flex;
            flex-direction: column;
            gap: 30px;
        }
        
    }

    .container-d{
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 55%; 
        margin-left: 50px;
        margin-top: 20px;
        overflow-x: auto;
        height: 60vh;
        
        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 5px;
        }
        .title {
            font-size: 2rem;
            font-family:  ${theme.fontFamily.bold}
        }

        .body {
            font-size: .955rem;
        }
    }

    .container-change-password{
        display: flex;
        flex-direction: column;
        width: 40%; 
        gap: 13px;
        margin-left: 50px;
        margin-top: 50px;
        height: 60vh;

        .line {
            display: flex;
            flex-direction: column;
            gap: 0px;
            width: 100%;

            .title {
                font-size: 1.1rem;
                margin-left: 10px;
                font-family:  ${theme.fontFamily.regular};
                color: #171717;
            }

            .input-box{
                display: flex;
                border-radius: 100px;
                justify-content: space-between;
                width: 95%;
                padding: 5px 2.5%;
                background-color: #e4e4e4;

                input{
                    width: 88%;
                    border: none;
                    outline: none;
                    background-color: rgba(0,0,0,0);
                    font-size: .915rem;
                }

                .sufix {
                    font-family:  ${theme.fontFamily.regular};
                    user-select: none;
                    cursor: pointer;
                }
            }
        }

        .button {
            margin-top: 20px;
            background-color: #01bbb6;
            align-self: flex-start;
            padding: 2px 30px;
            border-radius: 100px;
            font-size: 1.1rem;
            color: white;
            cursor: pointer;
        }
    }

    .container-f{
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 55%; 
        margin-left: 50px;
        margin-top: 20px;
        overflow-x: auto;
        height: 60vh;

        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 5px;
        }

        .title {
            font-size: 2rem;
            font-family:  ${theme.fontFamily.bold}
        }

        .body {
            font-size: .955rem;
        }
    }

    .container-chats, .notifications{
        display: flex;
        flex-direction: column;
        width: 40%;     
        margin-left: 50px;
        gap: 10px;
        overflow: auto;
        overflow-x: hidden;
        height: calc(60vh + 50px);
        ${({ nothing }) => (nothing ? 'justify-content: center;' : '')}
        ${({ nothing }) => (nothing ? 'align-items: center;' : '')}
        
        .nothing-chat, .nothing {
            font-size: 1.2rem;
        }

        &::-webkit-scrollbar {
            width: 10px; 
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
            background: #888; 
            border-radius: 5px; 
        }
    }

    .container-chat {
        display: flex;
        flex-direction: column;
        width: 55%; 
        gap: 20px;
        margin-left: 50px;
        margin-top: 50px;
        position: relative;
        height: 60vh;

        .main {
            width: 70%;
            border-radius: 17px;
            position: relative;

            .back {
                position: absolute;
                margin-top: -40px;
                cursor: pointer;
            }


            .header-chat {
                background-color: #e5fffe;
                display: flex;
                align-items: center;
                gap: 10px;
                border: 1px solid gray;
                border-radius: 17px;
                padding: 5px 0px;
                flex-wrap: wrap;

                img {
                    margin-left: 20px;
                    border-radius: 100px;
                    border: 2px solid white;
                    width: 60px;
                    height: 60px;
                }

                .username {
                    font-size: 1.2rem;
                    font-weight: 600;
                }
            }

            .body-chat {
                background-color: #e5fffe;
                overflow: auto;
                display: flex;
                flex-direction: column;
                gap: 10px;
                height: 50vh;
                margin-top: -15px;
                overflow-y: visible;
                border: 1px solid gray;
                border-top: none;
                border-bottom: none;

                &::-webkit-scrollbar {
                    width: 10px; 
                }

                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }

                &::-webkit-scrollbar-thumb {
                    background: #888; 
                    border-radius: 5px; 
                }

                .space {
                    height: 40px;
                }

                .message {
                    width: 90%;
                    margin-bottom: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    margin: 0 auto;
                    padding-bottom: 10px;

                    span {
                        width: 80%;
                    }

                    .username {
                        font-size: 1rem;
                        font-weight: 600;
                    }

                    .moment{
                        font-size: .7rem;
                    }

                    &.received {
                        width: 80%;
                        margin-left: 5%;
                        margin-right: 15%;
                    }

                    &.sended{
                        width: 80%;
                        margin-left: 15%;
                        margin-right: 5%;
                        align-self: flex-end;
                        display: flex; 
                        text-align: end;

                        span {
                            width: 100%;
                            text-align: end;
                        }
                    }
                }
            }

            .inputbox {
                border: 1px solid gray;
                border-top: none;
                border-bottom-left-radius: 17px;
                border-bottom-right-radius: 17px;
                background-color: #e5fffe;


                .input-container {
                    transform: translateY(-5px);
                    border-radius: 100px;
                    width: 95%;
                    margin: 0 auto;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-color: white;
                    border: 1px solid gray;
                    padding: 4px 0px;

                    input {
                        width: calc(80%);
                        border-radius: 100px;
                        border: none;
                        outline: none;
                        padding: 2px 12px;
                        font-size: 1.15rem;
                    }

                    svg {
                        margin-right: 12px;
                    }
                }
            }

            
        }
    }

    @media (max-width: ${theme.breackpoint.mobile_2}) {
        margin: 30px 5%;
        height: 80vh;
        

        .container-a, .container-b,
        .container-c, .container-d,
        .container-change-password, 
        .container-f, .container-chats, .notifications,
        .container-chat {
            width: 100%;
            margin-left: 0;
            position: relative;
        }

        .container-chat {
            margin: 0;
            height: 100%;

            .back {
                display: none;
            }

            
        }


        .container-c {
            margin: 0;
            margin-bottom: 70px;
        }


        .container-f, .container-d, .container-c, .container-favorites{
            height: 80vh;
            width: 100%;
        }
        .container-favorites{
            margin: 0;
            margin-bottom: 50px;
        }
        .container-chat {
            .main {
                width: 100%;
            }
        }
    }
    
    @media (max-width: ${theme.breackpoint.tabletsAndSmallScreens}) and (min-width: ${theme.breackpoint.mobile_2}) {
        margin: 30px 2%;

        .container-a, .container-b,
        .container-c, .container-d,
        .container-change-password, .container-f {
            width: 50%;
        }
    }
    
    @media (max-width: ${theme.breackpoint.commonScreensAndLaptops}) and (min-width: ${theme.breackpoint.tabletsAndSmallScreens}) {
        margin: 30px 8%;

        .container-a, .container-b,
        .container-c, .container-d,
        .container-change-password, .container-f {
            width: 50%;
        }
    }
    
    @media (max-width: ${theme.breackpoint.largeScreens}) and (min-width: ${theme.breackpoint.commonScreensAndLaptops}) {
        margin: 30px 8%;

        .container-a, .container-b,
        .container-c, .container-d,
        .container-change-password, .container-f {
            width: 50%;
        }
    }
    
    @media (max-width: ${theme.breackpoint.bigScreens}) and (min-width: ${theme.breackpoint.largeScreens}) {
        
    }
    
`

export default WrapperMyData