import React from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';

const mapContainerStyle = {
  height: "100%",
  width: "100%"
};

const center = {
  lat: -20.8176,
  lng: -49.3775
};

// Função para gerar o SVG do marcador com largura ajustada ao texto
const createCustomIcon = (price) => {
  // Formata o preço para Real (R$)
  const formattedPrice = price.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 0
  });

  // Calcula a largura do retângulo com base no comprimento do texto
  const width = Math.max(40, formattedPrice.length * 7 + 20);

  // SVG do marcador personalizado com largura ajustada ao conteúdo
  const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="60" viewBox="0 0 ${width} 60">
      <!-- Retângulo com cor tema e bordas arredondadas -->
      <rect x="5" y="5" width="${width - 10}" height="35" rx="8" ry="8" fill="#01bbb6"/>

      <!-- Texto do preço em branco -->
      <text x="${width / 2}" y="28" text-anchor="middle" font-size="12" font-family="Nunito-medium" font-weight="bold" fill="white">
        ${formattedPrice}
      </text>

      <!-- "Tail" (triângulo) com cor tema abaixo do retângulo -->
      
    </svg>
  `;
  return {
    url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`,
    scaledSize: { width, height: 60 },
    anchor: { x: width / 2, y: 50 } // Ajusta o ponto de ancoragem do marcador
  };
};

const MyMapComponent = (props) => {
  const navigate = useNavigate();

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={center} 
      zoom={10}
    >
      {props.properties && props.properties.map(place => (
        <Marker 
          key={place.id}
          position={{ lat: place.coords.latitude, lng: place.coords.longitude }}
          icon={createCustomIcon(place.price)} // Usa o ícone SVG personalizado com o preço formatado
          title={place.name}
          onClick={() => navigate(`/imovel/${place.id}`)}
        />
      ))}
    </GoogleMap>
  );
};

export default MyMapComponent;
