import styled from "styled-components";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

const WrapperSlideShowTypeC = styled.div`
    cursor: grab;
    overflow: hidden; /* Garante que o overflow seja escondido */
    width: 100%; /* Define a largura do wrapper como 100% do container pai */
    user-select: none;

    .inner {
        display: flex;
        gap: 15px;

        .image {
            min-width: 400px;
            position: relative;

            img {
                max-width: 400px;
                min-height: 300px;
                pointer-events: none;
            }
        }
    }
`;

const SlideShowTypeC = ({ images }) => {
    const CarouselRef = useRef(null);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        const updateWidth = () => {
            if (CarouselRef.current) {
                // Atualiza a largura total do carousel
                const totalWidth = CarouselRef.current.scrollWidth - CarouselRef.current.offsetWidth;
                setWidth(totalWidth);
            }
        };

        updateWidth(); // Chama na primeira renderização
        window.addEventListener("resize", updateWidth); // Atualiza na mudança de tamanho da janela

        return () => {
            window.removeEventListener("resize", updateWidth); // Limpa o listener ao desmontar
        };
    }, []);

    return (
        <WrapperSlideShowTypeC>
            <motion.div
                className="images"
                whileTap={{ cursor: "grabbing" }}
                ref={CarouselRef}
            >
                <motion.div
                    className="inner"
                    drag="x"
                    dragConstraints={{ right: 0, left: -width }} // Usar a largura calculada
                >
                    {images.map((image, index) => (
                        <motion.div className="image" key={index}>
                            <img src={image} alt={`Slide ${index}`} />
                        </motion.div>
                    ))}
                </motion.div>
            </motion.div>
        </WrapperSlideShowTypeC>
    );
};

export default SlideShowTypeC;
