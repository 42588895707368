import WrapperMyProperty from "./MyProperty.styles";
//import SquareMeterIcon from "../../imgs/SVG/METRAGEM.svg"
//import GarageIcon from "../../imgs/SVG/GARAGEM.svg"
//import BathroomIcon from "../../imgs/SVG/BANHEIRO.svg"
//import BedroomIcon from "../../imgs/SVG/QUARTO.svg"
import Switch from 'react-ios-switch';
import { RxRulerSquare } from "react-icons/rx";
import { MdOutlineDirectionsCarFilled } from "react-icons/md";
import { FaShower } from "react-icons/fa6";
import { IoBedOutline } from "react-icons/io5";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { arrayRemove, doc, updateDoc, getDoc } from "firebase/firestore";
import { db, toggleFavorite } from "../../hooks/firebase";
import { TbCircleXFilled } from "react-icons/tb";
import { FaHeart } from "react-icons/fa";
import { togglePropertyActive, deleteProperty } from "../../hooks/firebase";
import { FaX } from "react-icons/fa6";


function intToReal(valor) {
    return valor.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });
}

const desfavoritarImovel = async (propertyId, currentUser) => {
    const imovelRef = doc(db, 'properties', propertyId);
    try {
        await updateDoc(imovelRef, {
            likedBy: arrayRemove(currentUser)
        });
    } catch (error) {
        console.error("Erro ao desfavoritar o imóvel:", error);
    }
};


const MyProperty = ({data, hidden}) => {
    let [active, setActive] = useState(data.active)
    const [deleteConfirm, setDeleteConfirm] = useState(false)
    const plans = [ 
        {code: "WvFwQjeykfQAZcc2OZ0W", name: "Grátis"},
        {code: "OVjjz0xxONzOqigRmKdA", name: "Anual"},
    ]
    const navigate = useNavigate()
    
    function editProperty(){
        navigate(`/editar-anuncio/${data.id}`)
    }

    function viewProperty(){
        navigate(`/imovel/${data.id}`)
    }

    function handleRemoveFromFavorites(e){
        e.currentTarget.parentNode.parentNode.style.display = "none"
        toggleFavorite(data.currentUser, data.id)
    }

    function handleDeleteProperty(e) {
        e.currentTarget.parentNode.parentNode.style.display = "none"
        //deleteProperty(data.id)
    }

    function handleToggleActive() {
        const newActiveStatus = !active;
        setActive(newActiveStatus);
        togglePropertyActive(data.id);
    }

    function handleConfirmDelete() {
        setDeleteConfirm(!deleteConfirm)
    }
    console.log(data)
    return <WrapperMyProperty>
        <div className="img-container" onClick={viewProperty}>
            <img src={data.images[0]} alt="" title="Ver imóvel" />
        </div>
        <div className="body">
            <span className="price" onClick={viewProperty}>{intToReal(data.price)}</span>
            <span className="location" onClick={viewProperty}>{data.street}, {data.number}, {data.neightboard}, {data.city} - {data.state}</span>
            <div className="infos" onClick={viewProperty}>
                <div className="info">
                    <RxRulerSquare />
                    {data.squareMeter + " "}
                    m²
                </div>
                <div className="info">
                    <MdOutlineDirectionsCarFilled />
                    {data.garage}
                    {data.garage === 1 ?
                    ' Vaga' :
                    ' Vagas'
                    }   
                </div>
                <div className="info">
                    <FaShower />
                    {data.bathroom}
                    {data.bathroom === 1 ?
                    ' Banheiro' :
                    ' Banheiros'
                    }
                </div>
                <div className="info">
                    <IoBedOutline />
                    {data.bedroom}
                    {data.bedroom === 1 ?
                    ' Quarto' :
                    ' Quartos'
                    }
                </div>
            </div>
            {!hidden && <div className="actions">
                <span className="button delete" onClick={handleConfirmDelete}>
                    Excluir
                </span>
                <span className="button edit" onClick={editProperty}>Editar</span>
                {data.status === "PENDING" ? 
                    <span className="button turbo">Pagamento</span>
                    :
                    <span className="button turbo">
                        {data.planId === "OVjjz0xxONzOqigRmKdA" ?
                            "Anual"    
                            :
                            "Grátis"
                        }
                    </span>
                }
                <div className="active-switch-container">
                    {active ? "Ativado " : "Pausado"}
                    <Switch
                        checked={active}
                        onChange={handleToggleActive}
                        onColor="#08bbb5"
                        className="switch"
                    />
                </div>
            </div>}
            {hidden && <span className="button-remove" onClick={handleRemoveFromFavorites}>
                <FaHeart />
            </span>}
        </div>
        {deleteConfirm && 
            <div className="confirmDelete">
                <div className="top">
                    <span>Deseja mesmo excluir este imóvel?</span>
                    <span className="info">Ação irreversível</span>
                </div>
                <div className="action" onClick={handleConfirmDelete}>
                    <FaX />
                </div>
                <span className="button" onClick={handleDeleteProperty}> 
                    Sim
                </span>
            </div>
        }
    </WrapperMyProperty>
}

export default MyProperty